<script>
import PageHeader from "@/components/PageHeader.vue";
import {
  configureCreate, configureInfo,
  configureUpdate,
  developerAll,
  distributorAll,
  roofTypeAll
} from "@/api/common";

export default {
  name: "RentConfig",
  components: {PageHeader},
  data(){
    return {
      pageForm: {
        developersId: '',
        distributorId:'',
        type: '',
        type1: '',
        type2: '',
        startDate: '',
        endDate: '',
        _date: [],
        periods: '',
        rent: '',
        rent_yen: '',
        rent1: '',
        rent1_yen: '',
        rent2: '',
        rent2_yen: '',
        utype: 0,
      },
      pageRules: {
        developersId: [
          {required: true, message: "请选择开发商", trigger: "blur"},
        ],
        type: [
          {required: true, message: "请选择租金类型", trigger: "blur"},
        ],
        _date: [
          {required: true, message: "请选择有效期", trigger: "blur"},
        ],
        periods: [
          {required: true, message: "请选择期数", trigger: "blur"},
        ],
        roofType: [
          {required: true, message: "请选择屋顶类型", trigger: "blur"},
        ],
      },
      developer_options: [],
      distributor_options: [],
      roof_type_options: [],
      type_options: [
        {
          label: '通用型',
          value: 0,
          type1: '',
          type2: '',
        },
        {
          label: '10+15型',
          value: 1,
          type1: 10,
          type2: 15,
        },
        {
          label: '5+20型',
          value: 2,
          type1: 5,
          type2: 20,
        },
      ],
      periods_options: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '6',
          value: 6,
        },
        {
          label: '12',
          value: 12,
        },
      ],
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.getInfo()
    }
    this.getDeveloperOptions()
    this.getDistributorOptions()
    this.getRoofTypeOptions()
  },
  methods: {
    getInfo(){
      configureInfo(this.id).then(item => {
        this.pageForm = {
          id: item.id,
          developersId: item.developersId,
          distributorId: item.distributorId,
          roofType:item.roofType,
          type: item.type,
          type1: item.type1,
          type2: item.type2,
          startDate: item.startDate,
          endDate: item.endDate,
          _date: [item.startDate, item.endDate],
          periods: item.periods,
          rent: item.rent,
          rent_yen: item.rent ? item.rent / 100 : '',
          rent1: item.rent1,
          rent1_yen: item.rent1 ? item.rent1 / 100 : '',
          rent2: item.rent2,
          rent2_yen: item.rent2 ? item.rent2 / 100 : '',
          utype: item.utype,
          version: item.version
        }
      })
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        if(that.pageForm.developersId===''&&that.pageForm.distributorId===''){
          that.$message.error("请选择一个开发商或者经销商！")
          return
        }
        if(that.pageForm.distributorId!==''&&that.pageForm.distributorId!==null){
          that.pageForm.utype=1
        }else {
          that.pageForm.utype=0
        }
        that.page_form_loading = true
        let func
        if (this.id) {
          func = configureUpdate
        } else {
          func = configureCreate
        }
        func(that.pageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          setTimeout(() => {
            this.$router.replace('/admin/rent-set-developer')
          }, 500)
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    cancel(){
      this.$router.replace('/admin/rent-set-developer')
    },
    yenChange(val, key) {
      this.pageForm[key] = parseFloat(val) * 100
    },
    dateChange(val) {
      if (val) {
        this.pageForm.startDate = val[0]
        this.pageForm.endDate = val[1]
      } else {
        this.pageForm.startDate = ''
        this.pageForm.endDate = ''
      }
    },
    typeChange(val) {
      let type = this.type_options.find(item => {
        return item.value == val
      })
      if (type) {
        this.pageForm.type1 = type.type1
        this.pageForm.type2 = type.type2
      }
    },
    getDeveloperOptions() {
      let that = this
      developerAll().then(res => {
        that.developer_options = res
      })
    },
    getDistributorOptions() {
      let that = this
      distributorAll().then(res => {
        that.distributor_options = res
      })
    },
    getRoofTypeOptions() {
      let that = this
      roofTypeAll().then(res => {
        that.roof_type_options = res
      })
    },
  }
}
</script>

<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-header">
          <span style="font-weight: bold;">{{ $route.meta.title }}</span>
        </div>
        <div class="page-card-body">
          <el-form :model="pageForm" :rules="pageRules"
                   label-width="120px" ref="pageForm"
                   inline label-position="top">
            <el-row>
              <el-col :span="5">
                <el-form-item label="开发商" style="width: 100%;">
                  <el-select style="width: 100%;" v-model="pageForm.developersId" filterable clearable placeholder="开发商">
                    <el-option
                        v-for="item in developer_options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item label="经销商" style="width: 100%;">
                  <el-select style="width: 100%;" v-model="pageForm.distributorId" filterable clearable placeholder="经销商">
                    <el-option
                        v-for="item in distributor_options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="租金类型" prop="type">
                  <el-select style="width: 100%;" v-model="pageForm.type" placeholder="租金类型" @change="typeChange">
                    <el-option v-for="type in type_options" :key="type.value" :label="type.label"
                               :value="type.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1">
                <el-form-item style="width: 100%;" label="期数" prop="periods">
                  <el-select style="width: 100%;" v-model="pageForm.periods" placeholder="期数">
                    <el-option v-for="periods in periods_options" :key="periods.value" :label="periods.label"
                               :value="periods.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="5">
                <el-form-item style="width: 100%;" label="有效期" prop="_date">
                  <el-date-picker style="width: 100%;"
                      @change="dateChange"
                      v-model="pageForm._date"
                      type="daterange"
                      value-format="yyyy-MM-dd"
                      range-separator="-"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1" v-if="pageForm.distributorId!==''&&pageForm.distributorId!=null">
                <el-form-item style="width: 100%;" label="屋顶类型" prop="roofType" >
                  <el-select style="width: 100%;" v-model="pageForm.roofType" placeholder="屋顶类型">
                    <el-option v-for="type in roof_type_options" :key="type.type" :label="type.name"
                               :value="type.type"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1" v-if="pageForm.type == 0">
                <el-form-item style="width: 100%;"
                              label="租金价格"
                              prop="rent_yen"
                              :rules='[ {required: true, message: "请输入租金价格", trigger: "blur"},]'>
                  <el-input v-model.number="pageForm.rent_yen" placeholder="租金价格"
                            @change="(val) => yenChange(val, 'rent')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1" v-if="pageForm.type != 0">
                <el-form-item  style="width: 100%;" :label="'租金价格前' + pageForm.type1 + '年'" prop="rent1_yen" :rules='[
                    {required: true, message: "请输入租金价格", trigger: "blur"},
                  ]'>
                  <el-input v-model="pageForm.rent1_yen" :placeholder="'租金价格前' + pageForm.type1 + '年'"
                            @change="(val) => yenChange(val, 'rent1')"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="1" v-if="pageForm.type != 0">
                <el-form-item  style="width: 100%;" :label="'租金价格后' + pageForm.type2 + '年'" prop="rent2_yen" :rules='[
                      {required: true, message: "请输入租金价格", trigger: "blur"},
                    ]'>
                  <el-input v-model="pageForm.rent2_yen" :placeholder="'租金价格后' + pageForm.type2 + '年'"
                            @change="(val) => yenChange(val, 'rent2')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="dialog-footer">
              <el-button @click="cancel">取 消</el-button>
              <el-button @click="submit('pageForm')" type="primary">确 定</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-container{
  .dashboard-container{
    .page-card{
      .page-card-header{
        padding-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: none;
        &::after{
          padding-top: 20px;
          content: ' ';
          width: 100%;
          height: 1px;
          border-bottom: 1px solid #ebeef5;
        }
      }
    }
  }
}
.page-card-body {
  padding: 20px;
  .table-action-item {
    display: inline-block;
    cursor: pointer;
    color: $--color-primary;

    & + .table-action-item {
      margin-left: 10px;
    }
  }
}
</style>