import { render, staticRenderFns } from "./RentConfig.vue?vue&type=template&id=58849751&scoped=true"
import script from "./RentConfig.vue?vue&type=script&lang=js"
export * from "./RentConfig.vue?vue&type=script&lang=js"
import style0 from "./RentConfig.vue?vue&type=style&index=0&id=58849751&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58849751",
  null
  
)

export default component.exports